import moment from 'moment-timezone';
import _ from "lodash";

export default class {

  static emptyStr(val) {
    if (typeof val == "undefined" || typeof val == "object" && val == null)
      return "";
    return val.toString();
  }

  static valOrNull(val) {
    if (typeof val == "undefined" || val == null)
      return null;
    return val;
  }

  static formatPhone(phone) {
    if (typeof phone != "string")
      phone = "";
    phone = phone.replace(/[^0-9]/g, "");
    if (phone == "")
      return "";
    return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, phone.length - 6) + '-' + phone.substr(-4);
  }

  static formatCpf(cpf) {
    if (typeof cpf != "string")
      return "";

    cpf = cpf.replace(/[^0-9]/g, '');

    if (cpf.length != 11)
      return "";

    return cpf.substr(0, 3) + "." + cpf.substr(3, 3) + "." + cpf.substr(6, 3) + "-" + cpf.substr(9);
  }

  static formatCpfCnpj(cpf) {
    if (typeof cpf != "string")
      return "";

    cpf = cpf.replace(/[^0-9]/g, '');

    if (cpf.length != 11 && cpf.length != 14)
      return "";

    if (cpf.length == 11)
      return cpf.substr(0, 3) + "." + cpf.substr(3, 3) + "." + cpf.substr(6, 3) + "-" + cpf.substr(9);

    return cpf.substr(0, 2) + "." + cpf.substr(2, 3) + "." + cpf.substr(5, 3) + "/" + cpf.substr(8, 4) + "-" + cpf.substr(12);
  }

  static formatCurrency(val, prefix = "R$", getNumber = false) {
    if (typeof val == "string")
        val = parseFloat(val);
    else if (typeof val != "number" || isNaN(val)) {
        if (getNumber)
            return 0;
        return "";
    }

    if(getNumber)
        return parseFloat(val.toFixed(2));
    return ((prefix != "")?prefix + " ":"") + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)).replace("R$ ","");
  }

  static formatDecimal(val) {
    if (typeof val == "string")
      val = parseFloat(val);
    else if (typeof val != "number")
      return null;

    val = new Intl.NumberFormat('pt-BR').format(val);

    if (val.indexOf(",") == -1) {
      val += ',00';
    } else if (val.indexOf(",") == (val.length - 2)) {
      val += '0';
    }

    return val;
  }

  static formatDate(date, format = "DD/MM/YYYY HH:mm:ss") {
    if (typeof date != "undefined" && date != null && date != "") {
        return moment(date, true).tz('America/Sao_Paulo').format(format);
    }
    return "";
  }

  static formatDateTimeToBr(date) {
    if (typeof date != "string" || date == "")
      return null;

    date = date.split(" ");

    return date[0].split("-").reverse().join("/") + " " + date[1];
  }

  static formatDateToBr(date) {
    if (typeof date != "string" || date == "")
      return null;
    return date.split("-").reverse().join("/");
  }

  static formatDateToMysql(date) {
    if (typeof date != "string" || date == "")
      return null;
    return date.split("/").reverse().join("-");
  }

  static getStdObject(obj, padrao = null, strict = false) {
    if (typeof padrao == "undefined")
      padrao = null;
    if (typeof strict != "boolean")
      strict = false;
    if (typeof obj == "undefined" || obj == null)
      return padrao;
    if (strict && typeof obj != "object")
      return padrao;
    return _.cloneDeep(obj);
  }

  static daysFromNow(date) {

    if (!date)
      return -1;

    const end = moment(date, true).tz("America/Sao_Paulo");
    const now = moment().tz("America/Sao_Paulo");

    const duration = moment.duration(end.diff(now));

    return parseInt(duration.asDays());

  }

  static inputNumberFocus(event) {
    event.target.setSelectionRange(0, event.target.value.length);
  }

  // #region Validações Booleanas (From: SDK) //

  static isMObject(val) {
    if (typeof val != "object")
      return false;

    if (val == null)
      return false;

    if (Object.keys(val).length == 0)
      return false;

    return true;
  }

  static isCPF(strCPF) {
    strCPF = strCPF.replace(/[^0-9]/g, "");

    let i;
    let Soma = 0;
    let Resto;

    if (strCPF == "00000000000") return false;

    for (i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);

    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;

    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);

    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;

    if (Resto != parseInt(strCPF.substring(10, 11))) return false;

    return true;
  }

  static isCNPJ(cnpj) {
    let i;

    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != digitos.charAt(1)) return false;

    return true;

  }

  static isEmpty(val) {
    if (val === undefined || val === null)
      return true;

    switch (typeof val) {
      case "string": {
        return val.trim().length == 0;
      }
      case "object": {
        return Object.keys(val).length == 0;
      }
    }

    return false;
  }

  static isValidParams(params, fields, ignoreUndefined = false) {

    ignoreUndefined = this.validBoolean(ignoreUndefined);

    fields = this.validArray(fields);

    if (fields.length == 0)
      return [];

    params = this.validObject(params, {}, true);

    let erros = [];
    for (const f of fields) {
      const val = this.getPathOfObject(params, f);
      if (val === undefined && ignoreUndefined)
        continue;
      if (!this.isValid(val, true))
        erros.push(f);
    }

    return erros;

  }

  static isValid(val, validEmpty = false) {
    // TODO Adequar para array ou objeto vazio e valores diferentes dos padrões, como números negativos e/ou positivos
    let valid = !(val === undefined || val === null || val === "" || val === 0);
    if (valid && validEmpty) {
      return this.isEmpty(val) == false;
    }
    return valid;
  }

  // #endregion Validações Booleanas (From: SDK) //

  // #region Validações com Correção (From: SDK) //

  static validParams(params, fields, options) {

    const options_defaults = {
      message: "Preencha corretamente todas as informações obrigatórias!",
      code: "FIELDS_ERROR",
      ignoreUndefined: false
    };

    options = { ...options_defaults, ...options };

    const erros = this.isValidParams(params, fields, options.ignoreUndefined);

    if (erros.length > 0) {

      throw {
        success: false,
        message: options.message,
        error: {
          code: options.code,
          message: options.message,
          fields: erros
        }
      };

    }

  }

  static validString(val, def = "", notEmpty = false) {
    if (typeof def != "string")
      def = "";

    if (typeof val == "string") {
      if (notEmpty == false || val.trim() != "")
        return val;
    }

    return def;
  }

  static validFloat(val, def = 0) {
    if (typeof def != "number")
      def = 0;

    if (typeof val == "number")
      return parseFloat(val.toFixed(2));

    return def;
  }

  static validInteger(val, def = 0) {
    if (typeof def != "number")
      def = 0;

    if (typeof val == "number")
      return parseInt(val.toString());

    return def;
  }

  static validBoolean(val, def = false) {
    if (typeof def != "boolean")
      def = false;

    if (typeof val == "boolean")
      return val;

    return def;
  }

  static validObject(val, def = {}, notNull = true) {
    if (typeof def != "object")
      def = null;

    if (typeof val == "object")
      if (notNull == false || val != null)
        return val;

    return def;
  }

  static validArray(val, def = []) {
    if (typeof def != "object" || !Array.isArray(def))
      def = [];

    if (typeof val == "object" && val != null) {

      if (Array.isArray(val)) {
        return val;
      }

      const k = Object.keys(val);
      let i;
      for (i = 0; i < k.length && k[i] == i; i++);
      if (i == k.length)
        return Object.values(val);

    }

    return def;
  }

  static validDate(val, def = null) {
    if (typeof def != "object")
      def = null;

    if (typeof val == "undefined" || val == null)
      return def;

    if (typeof val == "object" && val instanceof Date)
      return val;

    if (typeof val == "string" && /[0-2][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9].*/.test(val))
      return new Date(val);

    return def;
  }

  // #endregion Validações com Correção (From: SDK) //

  // #region Manipulação de Objetos (From: SDK) //

  static setPathOfObject(obj, path, value) {

    if (typeof path != "string" || path == "")
      return;

    const formatoresRegx = /(\[[^\]]*\])|(\{[^}]*\})/g
    const dadosFormatadores = path.match(formatoresRegx);

    path = path.replace(formatoresRegx, "#").split(".");

    if (path.length == 0)
      return;

    const pArr = [];
    let iDF = 0;
    for (const [i, p] of Object.entries(path)) {
      if (p === "#") {
        path[i] = dadosFormatadores[iDF++];
        if (path[i].startsWith("[")) {
          pArr.push(parseInt(i) - 1);
          path[i] = path[i].replace("[", "").replace("]", "");
        } else {
          path[i] = path[i].replace("{", "").replace("}", "");
        }
      }
    }

    let pObj = {};
    let nObj = pObj;
    for (let [i, p] of Object.entries(path)) {

      if (i == (path.length - 1)) {
        pObj[p] = value;
      } else {

        if (pObj[p] === undefined) {
          if (pArr.includes(parseInt(i)))
            pObj = pObj[p] = [];
          else
            pObj = pObj[p] = {};
        }

      }
    }

    _.merge(obj, nObj);

  }

  static unsetPathOfObject(obj, path) {

    if (typeof path != "string" || path == "")
      return false;

    path = path.split(".");

    if (path.length == 0)
      return false;

    const last = path.pop();

    for (const p of path) {
      if (typeof obj == "object")
        obj = obj[p];
      else {
        obj = null;
        break;
      }
    }

    if (obj != null) {

      if (typeof obj[last] == "undefined") {
        return false;
      }

      if (Array.isArray(obj)) {
        obj.splice(parseInt(last), 1);
      } else {
        delete obj[last];
      }

      return true;

    }

    return false;

  }

  static getPathOfObject(obj, path) {

    if (typeof path != "string" || path == "")
      return undefined;

    path = path.split(".");

    for (const p of path) {
      if (typeof obj == "object" && obj != null)
        obj = obj[p];
      else {
        obj = undefined;
        break;
      }
    }

    return obj;

  }

  // #endregion Manipulação de Objetos (From: SDK) //

}