<template>
	<div class="layout-profile">
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">{{ nome }}</span>
			<i class="pi pi-fw pi-cog"></i>
		</button>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><button class="p-link" @click="perfil"><i class="pi pi-fw pi-user"></i><span>Perfil</span></button></li>
                <li><button class="p-link" @click="logout"><i class="pi pi-fw pi-power-off"></i><span>Sair</span></button></li>
            </ul>
        </transition>
		
	</div>
</template>

<script>
import { getAuth, signOut} from "firebase/auth";

export default {
	data() {
		return {
			expanded: false,
			nome: ""
		}
	},
	created() {
		this.updateUser();
	},
	watch: {
		$route() {
			this.updateUser();
		}
	},
	methods: {
		onClick(event){
			this.expanded = !this.expanded;
			event.preventDefault();
		},
		logout() {
			signOut(getAuth());
		},
		perfil() {
			this.$router.push('/perfil');
		},
		updateUser() {
			if(this.$auth.existsUsuario())
				this.nome = this.$usuario.nome;
			else
				this.nome = "";
		}
	}
}
</script>

<style scoped>

</style>