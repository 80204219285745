import utils from "../support/utils";
import { reactive } from 'vue';

export default class Auth {

    #api = null;
    #usuario = null;
    #usuarioClean = null;
    #empresa = null;
    #empresaClean = null;
    #imobiliaria = null;

    constructor(api, params = {}) {

        api = utils.validObject(api, null, false);
        params = utils.getStdObject(params, {}, true);

        if(api == null)
            throw "[AUTH] API Inválida!";

        this.#api = api;
        this.#usuarioClean = utils.validObject(params.usuarioClean, null, false);
        this.#empresaClean = utils.validObject(params.empresaClean, null, false);

        this.#imobiliaria = reactive({});

        if(this.#usuarioClean == null)
            this.#usuario = reactive({});
        else
            this.#usuario = reactive(this.#usuarioClean);

        if(this.#empresaClean == null)
            this.#empresa = reactive({});
        else
            this.#empresa = reactive(this.#empresaClean);

    }

    get usuario() {
        return this.#usuario;
    }

    get empresa() {
        return this.#empresa;
    }

    get imobiliaria() {
        return this.#imobiliaria;
    }

    existsUsuario() {
        return this.#usuario != null && typeof this.#usuario.id == "string" && this.#usuario.id != "";
    }

    existsEmpresa() {
        return this.#empresa != null && typeof this.#empresa.id == "string" && this.#empresa.id != "";
    }

    existsImobiliaria() {
        return this.#imobiliaria != null && typeof this.#imobiliaria.id == "string" && this.#imobiliaria.id != "";
    }

    clearEmpresa() {

        for(const k of Object.keys(this.#empresa))
            delete this.#empresa[k];

        if(this.#empresaClean != null) {
            for(const [k,v] of Object.entries(this.#empresaClean))
                this.#empresa[k] = v;
        }

        this.#api.setIdEmpresa("");

    }

    clearImobiliaria() {

        for(const k of Object.keys(this.#imobiliaria))
            delete this.#imobiliaria[k];

        this.#api.setIdImobiliariaGlobal("");

        window.localStorage.setItem("JACYS/IMOBILIARIA", "");

    }

    clearUsuario() {

        for(const k of Object.keys(this.#usuario))
            delete this.#usuario[k];

        if(this.#usuarioClean != null) {
            for(const [k,v] of Object.entries(this.#usuarioClean))
                this.#usuario[k] = v;
        }

        // this.clearEmpresa();

    }

    setCleanUsuario(obj) {
        this.#usuarioClean = utils.validObject(obj, null, false);
        if(!this.existsUsuario())
            this.clearUsuario();
    }

    setCleanEmpresa(obj) {
        this.#empresaClean = utils.validObject(obj, null, false);
        if(!this.existsEmpresa())
            this.clearEmpresexistsEmpresa();
    }

    async loadUsuario(id = "") {

        if(typeof id != "string" || id.trim() == "") {
            if(this.#usuario != null && typeof this.#usuario.id == "string") {
                id = this.#usuario.id;
            }
        }

        if(id == "") {
            const message = "O ID do Usuário é inválido!";
            console.error("[AUTH] " + message);
            return {
                success: false,
                message: message
            }
        }

        this.clearUsuario();

        try {

            let idEmpresa = utils.emptyStr(window.localStorage.getItem("JACYS/EMPRESA"));
            let idImobiliaria = utils.emptyStr(window.localStorage.getItem("JACYS/IMOBILIARIA"));

            this.#api.setIdEmpresa("");

            const res = await this.#api.get("/usuarios/" + id);
            if (res.data.success) {

                Object.assign(this.#usuario, res.data.data);
                
                if(idEmpresa == "") {
                    idEmpresa = utils.emptyStr(this.#usuario.idEmpresa);
                }

                await (new Promise(resolve => setTimeout(resolve, 1000))); // POG IOS                

                const res_empresa = await this.loadEmpresa(idEmpresa);

                if(res_empresa.success) {

                    const idImobiliariaUsuario = utils.validString(utils.getPathOfObject(this.#usuario, "imobiliaria.id"));
                    if(idImobiliariaUsuario != "") {
                        idImobiliaria = idImobiliariaUsuario;
                    }
    
                    if(idImobiliaria) {

                        const res_imobiliaria = await this.loadImobiliaria(idImobiliaria);

                        if(idImobiliariaUsuario != "" && res_imobiliaria.success == false) {
                            
                            const message = "Usuário e Empresa carregados. Porém, " + res_imobiliaria.message.substring(0,1).toLowerCase() + res_imobiliaria.message.substring(1);
                            console.error("[AUTH] " + message);

                            return {
                                success: false,
                                message: message
                            }

                        }

                    }

                    return {
                        success: true,
                    }    

                } else {

                    const message = "Usuário carregado. Porém, " + res_empresa.message.substring(0,1).toLowerCase() + res_empresa.message.substring(1);
                    console.error("[AUTH] " + message);
                    return {
                        success: false,
                        message: message
                    }

                }

            } else {

                const message = "O Usuário não existe no banco de dados! Contate o Suporte.";
                console.error("[AUTH] " + message);
                return {
                    success: false,
                    message: message
                }

            }

        } catch (e) {

            const message = "Erro ao carregar as informações do Usuário via API!";
            console.error("[AUTH] " + message);

            return {
                success: false,
                message: message
            }

        }

    }

    async loadEmpresa(id = "") {

        // TODO VALIDAR SE A EMPRESA INFORMADA TEM ACESSO PERMITIDO PARA O USUÁRIO LOGADO

        if(typeof id != "string" || id.trim() == "") {
            if(this.#empresa != null && typeof this.#empresa.id == "string") {
                id = this.#empresa.id;
            }
        }

        if(id == "") {
            const message = "O ID da Empresa é inválido!";
            console.error("[AUTH] " + message);
            return {
                success: false,
                message: message
            }
        }

        this.clearEmpresa();

        try {

            this.#api.setIdEmpresa(id);

            const res = await this.#api.get("/empresas/" + id);
            if (res.data.success) {

                Object.assign(this.#empresa, res.data.data);                

                window.localStorage.setItem("JACYS/EMPRESA", id);

                return {
                    success: true,
                }

            } else {

                this.#api.setIdEmpresa("");

                const message = "A Empresa não existe no banco de dados! Contate o Suporte.";
                console.error("[AUTH] " + message);
                return {
                    success: false,
                    message: message
                }

            }

        } catch (e) {

            const message = "Erro ao carregar as informações da Empresa via API!";
            console.error("[AUTH] " + message);

            return {
                success: false,
                message: message
            }

        }

    }

    async loadImobiliaria(imobiliaria) {

        this.clearImobiliaria();

        let id = "";
        if(typeof imobiliaria == "object" && imobiliaria != null && typeof imobiliaria.id == "string") {
            id = imobiliaria.id;
        } else if(typeof imobiliaria == "string") {
            id = imobiliaria;
        }

        if(id == "") {
            const message = "O ID da Imobiliária é inválido!";
            console.error("[AUTH] " + message);
            return {
                success: false,
                message: message
            }
        }

        if(this.existsImobiliaria() && this.#imobiliaria.id == id)
            return;

        try {

            const res = await this.#api.get("/imobiliarias/" + id);
            if (res.data.success) {

                Object.assign(this.#imobiliaria, res.data.data);

                this.#api.setIdImobiliariaGlobal(id);

                window.localStorage.setItem("JACYS/IMOBILIARIA", id);

                return {
                    success: true,
                }

            } else {

                const message = "A Imobiliária não existe no banco de dados! Contate o Suporte.";
                console.error("[AUTH] " + message);
                return {
                    success: false,
                    message: message
                }

            }

        } catch (e) {

            const message = "Erro ao carregar as informações da Imobiliária via API!";
            console.error("[AUTH] " + message);

            return {
                success: false,
                message: message
            }

        }

    }

    userCan(idPermissao) {

        if(typeof idPermissao != "string" || idPermissao.trim() == "" || this.existsUsuario() == false)
            return false;

        if(this.#usuario.permissao.id == "JACYS_MASTER")
            return true;

        let permissoes = utils.validArray(this.#usuario.permissoes);

        if(permissoes.length == 0)
            return false;

        const regex = idPermissao.indexOf("*") != -1;
    
        let condition = null;
        if(regex) {
            condition = new RegExp(idPermissao);
        }

        return (permissoes.filter((el) => {
            if(regex)
                return condition.test(el);
            else
                return el == idPermissao;
        })).length > 0;

    }

    // validaPermissao(idPermissao, grantAdmin = false) {

    //     if(typeof idPermissao != "string" || idPermissao.trim() == "")
    //         return false;
        
    //     idPermissao = idPermissao.trim();

    //     let nivelPermissaoUsuario = "";

    //     if(this.#usuario != null) {

    //         if(grantAdmin == true && this.isPermissao("ADMIN"))
    //             return true;

    //         if(typeof this.#usuario.permicao == "string")
    //             nivelPermissaoUsuario = this.#usuario.permicao.toUpperCase();

    //         if(typeof this.#usuario.configuracoes == "object" && this.#usuario.configuracoes != null) {
            
    //             if(typeof this.#usuario.configuracoes[idPermissao] != "undefined") {

    //                 if(typeof this.#usuario.configuracoes[idPermissao] == "boolean")
    //                     return this.#usuario.configuracoes[idPermissao];

    //                 if(typeof this.#usuario.configuracoes[idPermissao].permitido == "boolean")
    //                     return this.#usuario.configuracoes[idPermissao].permitido;

    //             }

    //         }

    //     }

    //     if(this.#empresa != null) {

    //         if(typeof this.#empresa.configuracoes == "object" && this.#empresa.configuracoes != null) {
            
    //             if(typeof this.#empresa.configuracoes[idPermissao] != "undefined") {

    //                 if(typeof this.#empresa.configuracoes[idPermissao] == "boolean")
    //                     return this.#empresa.configuracoes[idPermissao];

    //                 if(typeof this.#empresa.configuracoes[idPermissao].permitido == "boolean")
    //                     return this.#empresa.configuracoes[idPermissao].permitido;

    //             }

    //         }

    //         if(nivelPermissaoUsuario != "" && typeof this.#empresa.permissoes == "object" && this.#empresa.permissoes != null) {
            
    //             let permissoesEmpresa = {};
    //             if(typeof this.#empresa.permissoes[nivelPermissaoUsuario] == "object"  && this.#empresa.permissoes[nivelPermissaoUsuario] != null)
    //                 permissoesEmpresa = this.#empresa.permissoes[nivelPermissaoUsuario];

    //             if(typeof permissoesEmpresa[idPermissao] != "undefined") {

    //                 if(typeof permissoesEmpresa[idPermissao] == "boolean")
    //                     return permissoesEmpresa[idPermissao];

    //                 if(typeof permissoesEmpresa[idPermissao].permitido == "boolean")
    //                     return permissoesEmpresa[idPermissao].permitido;

    //             }

    //         }

    //     }

    //     return false;
    // }

    // validaSenhaGerencial(idTipo, senha) {
        
    //     if(typeof idTipo != "string" || idTipo.trim() == "" || typeof senha != "string" || senha.trim() == "")
    //         return false;
        
    //     idTipo = idTipo.trim();

    //     if(idTipo.substring(0, 7) != "SENHAS_")
    //         idTipo = "SENHAS_" + idTipo;

    //     if(this.#empresa != null) {

    //         if(typeof this.#empresa.configuracoes == "object" && this.#empresa.configuracoes != null) {
            
    //             if(typeof this.#empresa.configuracoes[idTipo] == "string") {

    //                 return this.#empresa.configuracoes[idTipo] == senha;

    //             }

    //         }

    //     }

    //     return false;

    // }

    // isPermissao(permissao = "ADMIN", isNPC = false) {

    //     if( this.#usuario == null || typeof this.#usuario.permicao != "string" || (typeof permissao != "string" && Array.isArray(permissao) == false) )
    //         return false;

    //     const permissaoUsuario = this.#usuario.permicao.toUpperCase();

    //     if(permissaoUsuario.indexOf("NPC_") == 0) {
    //         if(isNPC && permissao != "NPC")
    //             return false;
    //         return true;
    //     }

    //     if(typeof permissao == "string")
    //         permissao = [permissao];
        
    //     for(const p of permissao) {
    //         if(p.toUpperCase() == permissaoUsuario)
    //             return true;
    //     }

    //     return false;

    // }

}