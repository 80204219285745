import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: true
        }
    },
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./pages/Dashboard.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/perfil',
        name: 'perfil',
        component: () => import('./pages/usuarios/Perfil.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/usuarios',
        name: 'usuarios',
        component: () => import('./pages/usuarios/Usuarios.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/usuario/:id?',
        name: 'usuario',
        component: () => import('./pages/usuarios/Usuarios.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pessoas',
        name: 'pessoas',
        component: () => import('./pages/pessoas/Pessoas.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pessoa/:id?',
        name: 'pessoa',
        component: () => import('./pages/pessoas/PessoasEdit.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pessoa-pj/:id?',
        name: 'pessoa-pj',
        component: () => import('./pages/pessoas/PessoasJuridicasEdit.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pessoas-vinculos-empregaticios',
        name: 'pessoas-vinculos-empregaticios',
        component: () => import('./pages/pessoas/VinculosEmpregaticios.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pessoa-vinculo-empregaticio/:id?',
        name: 'pessoa-vinculo-empregaticio',
        component: () => import('./pages/pessoas/VinculosEmpregaticios.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pessoas-residencias-situacoes',
        name: 'pessoas-residencias-situacoes',
        component: () => import('./pages/pessoas/ResidenciasSituacoes.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pessoa-residencia-situacao/:id?',
        name: 'pessoa-residencia-situacao',
        component: () => import('./pages/pessoas/ResidenciasSituacoes.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pessoas-ramos-atividades',
        name: 'pessoas-ramos-atividades',
        component: () => import('./pages/pessoas/RamosAtividades.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pessoa-ramo-atividade/:id?',
        name: 'pessoa-ramo-atividade',
        component: () => import('./pages/pessoas/RamosAtividades.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/locacoes-motivos',
        name: 'locacoes-motivos',
        component: () => import('./pages/locacoes/Motivos.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/locacao-motivo/:id?',
        name: 'locacao-motivo',
        component: () => import('./pages/locacoes/Motivos.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/locacoes-finalidades',
        name: 'locacoes-finalidades',
        component: () => import('./pages/locacoes/Finalidades.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/locacao-finalidade/:id?',
        name: 'locacao-finalidade',
        component: () => import('./pages/locacoes/Finalidades.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pessoas-estados-civis',
        name: 'pessoas-estados-civis',
        component: () => import('./pages/pessoas/EstadosCivis.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pessoa-estado-civil/:id?',
        name: 'pessoa-estado-civil',
        component: () => import('./pages/pessoas/EstadosCivis.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/base-conhecimentos',
        name: 'base-conhecimentos',
        component: () => import('./pages/baseConhecimento/BaseConhecimentos.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/base-conhecimento/:id?',
        name: 'base-conhecimento',
        component: () => import('./pages/baseConhecimento/BaseConhecimentos.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/processos/:emitidos?',
        name: 'processos',
        component: () => import('./pages/processos/Processos.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/processo/analise/:id',
        name: 'processo-analise',
        component: () => import('./pages/processos/ProcessoView.vue'), //() => import('./pages/processos/acoes/ProcessoAnalise.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/processo/:id',
        name: 'processo',
        component: () => import('./pages/processos/ProcessoView.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/processo',
        name: 'processoNovo',
        meta: {
            requiresAuth: true,
            freeLayout: false
        },
        component: () => import('./pages/processos/ProcessoEdit.vue'),
        children: [{
            path: '/processo',
            component: () => import('./pages/processos/etapas/Locacao.vue')
        },
        {
            path: '/processo/pretendentes',
            component: () => import('./pages/processos/etapas/Pretendentes.vue')
        },
        {
            path: '/processo/coberturas',
            component: () => import('./pages/processos/etapas/Coberturas.vue')
        },
        {
            path: '/processo/confirmacao',
            component: () => import('./pages/processos/etapas/Confirmacao.vue')
        }]
    },
    {
        path: '/processo-pj',
        name: 'processo-pjNovo',
        meta: {
            requiresAuth: true,
            freeLayout: false
        },
        component: () => import('./pages/processos-pj/ProcessoEdit.vue'),
        children: [{
            path: '/processo-pj',
            component: () => import('./pages/processos-pj/etapas/Locacao.vue')
        },
        {
            path: '/processo-pj/pretendentes',
            component: () => import('./pages/processos-pj/etapas/Pretendentes.vue')
        },
        {
            path: '/processo-pj/coberturas',
            component: () => import('./pages/processos-pj/etapas/Coberturas.vue')
        },
        {
            path: '/processo-pj/confirmacao',
            component: () => import('./pages/processos-pj/etapas/Confirmacao.vue')
        }]
    },
    // {
    //     path: '/processo/clonar/:id',
    //     name: 'processoClonar',
    //     meta: {
    //         requiresAuth: true,
    //         freeLayout: false
    //     },
    //     component: () => import('./pages/EmBreve.vue'),
    // },
    {
        path: '/processo/clonar/:id',
        name: 'processoClonar',
        meta: {
            requiresAuth: true,
            freeLayout: false
        },
        component: () => import('./pages/processos/ProcessoEdit.vue'),
        children: [{
            path: '/processo/clonar/:id',
            component: () => import('./pages/processos/etapas/Locacao.vue')
        },
        {
            path: '/processo/pretendentes/:id',
            component: () => import('./pages/processos/etapas/Pretendentes.vue')
        },
        {
            path: '/processo/coberturas/:id',
            component: () => import('./pages/processos/etapas/Coberturas.vue')
        },
        {
            path: '/processo/confirmacao/:id',
            component: () => import('./pages/processos/etapas/Confirmacao.vue')
        }]
    },
    {
        path: '/processo-pj/clonar/:id',
        name: 'processo-pjClonar',
        meta: {
            requiresAuth: true,
            freeLayout: false
        },
        component: () => import('./pages/processos-pj/ProcessoEdit.vue'),
        children: [{
            path: '/processo-pj/clonar/:id',
            component: () => import('./pages/processos-pj/etapas/Locacao.vue')
        },
        {
            path: '/processo-pj/pretendentes',
            component: () => import('./pages/processos-pj/etapas/Pretendentes.vue')
        },
        {
            path: '/processo-pj/coberturas',
            component: () => import('./pages/processos-pj/etapas/Coberturas.vue')
        },
        {
            path: '/processo-pj/confirmacao',
            component: () => import('./pages/processos-pj/etapas/Confirmacao.vue')
        }]
    },
    {
        path: '/imoveis',
        name: 'imoveis',
        component: () => import('./pages/imoveis/Imoveis.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/imovel/:id?',
        name: 'imovel',
        component: () => import('./pages/imoveis/ImoveisEdit.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/tickets',
        name: 'tickets',
        component: () => import('./pages/tickets/Tickets.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/ticket/:id',
        name: 'ticket',
        component: () => import('./pages/tickets/TicketEdit.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/permissoes',
        name: 'permissoes',
        component: () => import('./pages/usuarios/Permissoes.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/permissao/:id?',
        name: 'permissao',
        component: () => import('./pages/usuarios/Permissoes.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/imobiliarias',
        name: 'imobiliarias',
        component: () => import('./pages/imobiliarias/Imobiliarias.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/imobiliaria/:id?',
        name: 'imobiliaria',
        component: () => import('./pages/imobiliarias/ImobiliariasEdit.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/seguradoras',
        name: 'seguradoras',
        component: () => import('./pages/seguradoras/Seguradoras.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/seguradora/:id?',
        name: 'seguradora',
        component: () => import('./pages/seguradoras/SeguradorasEdit.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/capitalizacoes',
        name: 'capitalizacoes',
        component: () => import('./pages/capitalizacao/Titulos.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/capitalizacao',
        name: 'capitalizacao',
        component: () => import('./pages/capitalizacao/TitulosEdit.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/capitalizacao/:id',
        name: 'capitalizacaoshow',
        component: () => import('./pages/capitalizacao/TitulosShow.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    }

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
