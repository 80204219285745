import utils from "../support/utils";
import axios from 'axios';
import { getAuth } from "firebase/auth";

export default class API {

    #idEmpresa = "";
    #idEmpresaPrev = "";
    #usoUnicoEmpresa = false;
    #idImobiliaria = "";
    #idImobiliariaPrev = "";
    #idImobiliariaGlobal = "";
    #usoUnicoImobiliaria = false;

    constructor(api) {
        this.urlApi = api;
    }

    setIdImobiliariaGlobal(id = "") {
        this.#idImobiliariaGlobal = utils.emptyStr(id);
    }

    setIdImobiliaria(id = "", usoUnico = false) {
        this.#idImobiliariaPrev = this.#idImobiliaria;
        this.#usoUnicoImobiliaria = usoUnico;
        this.#idImobiliaria = utils.emptyStr(id);
    }

    setIdEmpresa(id = "", usoUnico = false) {
        this.#idEmpresaPrev = this.#idEmpresa;
        this.#usoUnicoEmpresa = usoUnico;
        this.#idEmpresa = utils.emptyStr(id);
    }

    async setToken(token) {
        this.token = token;
    }

    async setBearerToken(token) {
        this.token = 'Bearer ' + token;
    }

    async headers(idImobiliaria = "") {

        let token;
        if(typeof this.token == 'string') {
            token = this.token;
            this.token = null;
        } else {
            token = await getAuth().currentUser.getIdToken();
        }

        if(!token.includes(" ")) {
            token = 'Bearer ' + token;
        }
        
        const headers = {
            'Accept': 'application/json',
            'Authorization': token
        };

        if(this.#idEmpresa != "") {
            headers.idEmpresa = this.#idEmpresa;
            if(this.#usoUnicoEmpresa) {
                this.#idEmpresa = this.#idEmpresaPrev;
                this.#idEmpresaPrev = "";
                this.#usoUnicoEmpresa = false;
            }
        }

        if(utils.validString(idImobiliaria) != "") {
            headers.idImobiliaria = idImobiliaria;
        } else if(this.#idImobiliaria != "") {
            headers.idImobiliaria = this.#idImobiliaria;
            if(this.#usoUnicoImobiliaria) {
                this.#idImobiliaria = this.#idImobiliariaPrev;
                this.#idImobiliariaPrev = "";
                this.#usoUnicoImobiliaria = false;
            }
        } else if(this.#idImobiliariaGlobal != "") {
            headers.idImobiliaria = this.#idImobiliariaGlobal;
        }

        return headers;

    }

    async get(endpoint, data = null, idImobiliaria = "") {

        if(typeof endpoint != "string")
            return null;

        data = utils.validObject(data, {}, true);

        for(const [k,v] of Object.entries(data)) {
            if(Array.isArray(v))
                data[k] = v.join("|");
            else if(typeof v != "string") {
                delete data[k];
            }
        }

        let query = (new URLSearchParams(data)).toString();
        if(query != "")
            query = "?" + query;

        return axios({
            method: 'get',
            url: this.urlApi + endpoint + query,
            headers: await this.headers(idImobiliaria),
            data: null
        });

    }

    async post(endpoint, data = null, idImobiliaria = "") {

        if(typeof endpoint != "string")
            return null;

        if(typeof data != "object" || data == null)
            data = {};

        return axios({
            method: 'post',
            url: this.urlApi + endpoint,
            headers: await this.headers(idImobiliaria),
            "data" : data
        });

    }

    async put(endpoint, data = null, idImobiliaria = "") {

        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;

        return axios({
            method: 'put',
            url: this.urlApi + endpoint,
            headers: await this.headers(idImobiliaria),
            "data" : data
        });

    }

    async delete(endpoint, data = null, idImobiliaria = "") {

        if(typeof endpoint != "string")
            return null;

        if(typeof data != "object" || data == null)
            data = {};

        return axios({
            method: 'delete',
            url: this.urlApi + endpoint,
            headers: await this.headers(idImobiliaria),
            "data" : data
        });

    }

    async cache(endpoint, forceUpdate = false, log = false) {

        if(log) console.log("API CACHE: ", endpoint);

        let versionDatabase = "";

        if(!forceUpdate) {

            if(typeof endpoint != "string")
                return null;

            let versionLocal = "";

            const res = await this.get(endpoint + "?onlyVersion=true");
            
            if(typeof res.headers["X-Version"] == "string")
                versionDatabase = res.headers["X-Version"];
            else if(typeof res.headers["x-version"] == "string")
                versionDatabase = res.headers["x-version"];
            else
                console.warn("Api Cache (" + endpoint + "): Controle de Versão Indisponível para o Endpoint! O Download sempre será realizado!");

            if(versionDatabase != "") {

                const version = window.localStorage.getItem("JACYS/" + endpoint + "/version");

                if(version)
                    versionLocal = version;

                if(versionDatabase == versionLocal) {
                    if(log) console.log("Api Cache (" + endpoint + "): Carregamento Local.");
                    const data = window.localStorage.getItem("JACYS/" + endpoint);
                    if(typeof data == "string" && data != "")
                        return JSON.parse(data);
                    return null;
                }

            }

        }

        if(log) console.log("Api Cache (" + endpoint + "): Carregamento por Download.");

        const res = await this.get(endpoint);

        if(res.data.success) {

            if(typeof res.headers["X-Version"] == "string")
                versionDatabase = res.headers["X-Version"];
            else if(typeof res.headers["x-version"] == "string")
                versionDatabase = res.headers["x-version"];

            if(versionDatabase != "") {
                window.localStorage.setItem("JACYS/" + endpoint + "/version", versionDatabase);
                window.localStorage.setItem("JACYS/" + endpoint, JSON.stringify(res.data.data));
            }
            return res.data.data;

        }

        if(log) console.log("Api Cache (" + endpoint + "): Falha na execução!");

        return null;

    }

}