export default {
    install: (app) => {

        const loading = {};

        loading.show = async (context = null, message = "") => {

            if(typeof context == "string") {
                message = context;
                context = null;
            }

            if(typeof message != "string" || message == "")
                message = "";
            else
                message = '<div class="app-loading-message">' + message + '</div>';

            const div = document.createElement("div");
            div.classList.add("app-loading");
            div.classList.add("splash-screen");
            div.innerHTML = '<div class="splash-container"><div class="splash-double-bounce1"></div><div class="splash-double-bounce2"></div></div>' + message;

            if(context == null) {
                div.classList.add("app-loading-global");
                context = document.getElementById("app");
            } else {

                if(typeof context.$el.constructor.name == "string" && context.$el.constructor.name == "Text")
                    context = context.$el.nextElementSibling;
                else
                    context = context.$el;

            }

            context.setAttribute('data-old-position', context.style.position);

            context.style.position = 'relative';

            if(context.getElementsByClassName("app-loading splash-screen").length == 0)
                context.appendChild(div);

        };

        loading.message = (context = null, message = "") => {

            if(typeof context == "string") {
                message = context;
                context = null;
            }

            if(typeof message != "string")
                message = "";

            if(context == null) {
                context = document.getElementById("app");
            } else {
                context = context.$el;
            }

            for(const e of context.getElementsByClassName("app-loading splash-screen")) {

                const msgEl = e.getElementsByClassName("app-loading-message");

                if(message == "") {
                    if(msgEl.length == 1)
                        msgEl[0].remove();
                } else {
                    if(msgEl.length == 1) {
                        msgEl[0].innerHTML = message;
                    } else {

                        const div = document.createElement("div");
                        div.classList.add("app-loading-message");
                        div.innerHTML = message;
            
                        e.appendChild(div);

                    }
                }

            }

        };

        loading.hide = (context = null) => {

            if(context == null) {
                context = document.getElementById("app");
            } else {
                if(typeof context.$el.constructor.name == "string" && context.$el.constructor.name == "Text")
                    context = context.$el.nextElementSibling;
                else
                    context = context.$el;
            }

            if(context == null)
                return;

            context.style.position = context.getAttribute('data-old-position');

            context.removeAttribute('data-old-position');

            for(const e of context.getElementsByClassName("app-loading splash-screen")) {

                context.removeChild(e);

            }

        };

        app.config.globalProperties.$loading = loading;

    },
};