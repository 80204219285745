// #region GLOBALS //

const versao = "2.4.2";

const verificaVersao = async () => {
    let gv = await (await fetch("/version.txt?t=" + new Date().getTime())).text();
    if(gv != versao) {
        console.error("Divergência de Versão! ",gv + " -> " + versao);
        window.location.reload(true);
    }
};

verificaVersao();

setInterval(verificaVersao, 10000);

// #endregion GLOBALS //

// #region Importações do FW e Componentes //

import { createApp } from 'vue';
import { reactive } from 'vue';
import router from './router';
import App from './App.vue';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import PrimeVue from 'primevue/config';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './assets/layout/layout.scss';

// #endregion Importações do FW e Componentes //

// #region Importação dos Meus Componentes e Plugins //

import Utils from './support/utils';
import Loading from './plugins/Loading/Loading';

// #endregion Importação dos Meus Componentes e Plugins //

// #region Monitoramento //

import { Notifier } from '@airbrake/browser';

var airbrake = new Notifier({
    environment: ((window.location.hostname == 'localhost')?'development':'production'),
    version: versao,
    projectId: 527598,
    projectKey: 'e533ba975606144059684da2c79d1bed'
});

// #endregion Monitoramento //

// #region Firebase - Inicialização //

import { initializeApp } from "firebase/app";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBeJi3L_7cCUsC9uVuulo0EUByAPO03xJ8",
  authDomain: "jacys-615c9.firebaseapp.com",
  projectId: "jacys-615c9",
  storageBucket: "jacys-615c9.appspot.com",
  messagingSenderId: "446305043917",
  appId: "1:446305043917:web:1b59c9bc153fc324243084"
};

initializeApp(firebaseConfig);

// #endregion Firebase - Inicialização //

// #region APIs e Serviços //

import API from './services/api';
import API_UPLOAD from './services/apiUpload';
import Auth from './services/auth';

let api,apiUpload;
switch(window.location.hostname) {
    case "localhost": api = new API("http://localhost:5021"); break
    // case "localhost": api = new API("https://api.dev.jacys.com.br"); break;
    // case "localhost": api = new API("https://api.jacys.com.br"); break;
    case "dev.jacys.com.br": api = new API("https://api.dev.jacys.com.br"); break;
    case "jacys.com.br": api = new API("https://api.jacys.com.br"); break;
}

apiUpload = new API_UPLOAD("https://arquivo.jacys.com.br/upload");

const auth = new Auth(api);

// #endregion APIs e Serviços //

// #region Aplicação (APP) - Inicialização //

let app = false;

onAuthStateChanged(getAuth(), async (user) => {

    auth.clearUsuario();

    if(user) {

        try {

            const res = await auth.loadUsuario(user.uid);
            if(res.success == false) {
                alert(res.message);
                await signOut(getAuth());
                return;
            }

        } catch (e) {
            console.error(e);
            // await signOut(getAuth());
        }

    } else {

        window.localStorage.setItem("JACYS/EMPRESA", "");
        window.localStorage.setItem("JACYS/IMOBILIARIA", "");

    }

    if (app === false) {

        app = createApp(App);

        app.config.errorHandler = function (err, vm, info) {
            airbrake.notify({
              error: err,
              params: {info: info}
            });
        }

        app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' });
        app.config.globalProperties.$primevue = reactive({ ripple: true });

        app.config.globalProperties.$auth = auth;
        app.config.globalProperties.$usuario = auth.usuario;
        app.config.globalProperties.$empresa = auth.empresa;
        app.config.globalProperties.$imobiliaria = auth.imobiliaria;
        app.config.globalProperties.$api = api;
        app.config.globalProperties.$uapi = apiUpload;
        app.config.globalProperties.$utils = Utils;
        app.config.globalProperties.$versao = versao;

        // #region Meus Plugins //
        app.use(Loading);
        // #endregion Meus Plugins //

        app.use(ConfirmationService);
        app.use(ToastService);
        app.use(router);
        app.use(PrimeVue, {
            locale: {
                "startsWith" : "Comece com",
                "contains" : "Contenha",
                "notContains" : "Não contenha",
                "endsWith" : "Termine com",
                "equals" : "Seja Igual",
                "notEquals" : "Seja Diferente",
                "noFilter" : "Sem Filtro",
                "lt" : "Menor que",
                "lte" : "Menor ou Igual a",
                "gt" : "Maior que",
                "gte" : "Maior ou Igual a",
                "dateIs" : "Data é",
                "dateIsNot" : "Data não é",
                "dateBefore" : "Data é anterior a",
                "dateAfter" : "Data é posterior a",
                "clear" : "Limpar",
                "apply" : "Aplicar",
                "matchAll" : "Match All",
                "matchAny" : "Match Any",
                "addRule" : "Adicionar Regra",
                "removeRule" : "Remover Regra",
                "accept" : "Sim",
                "reject" : "Não",
                "choose" : "Selecionar",
                "upload" : "Upload",
                "cancel" : "Cancelar",
                "completed" : "Finalizado",
                "pending" : "Pendente",
                "dayNames" : ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
                "dayNamesShort" : ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                "dayNamesMin" : ['D','S','T','Q','Q','S','S'],
                "monthNames" : ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
                "monthNamesShort" : ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun','Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                "chooseYear" : "Selecione o Ano",
                "chooseMonth" : "Selecione o Mês",
                "chooseDate" : "Selecione o Dia",
                "prevDecade" : "Década Anterior",
                "nextDecade" : "Próxima Década",
                "prevYear" : "Ano Anterior",
                "nextYear" : "Próximo Ano",
                "prevMonth" : "Mês Anterior",
                "nextMonth" : "Próximo Mês",
                "prevHour" : "Hora Anterior",
                "nextHour" : "Próxima Hora",
                "prevMinute" : "Minuto Anterior",
                "nextMinute" : "Próximo Minuto",
                "prevSecond" : "Segundo Anterior",
                "nextSecond" : "Próximo Segundo",
                "am" : "am",
                "pm" : "pm",
                "today" : "Hoje",
                "weak" : "Fraca",
                "medium" : "Média",
                "strong" : "Forte",
                "passwordPrompt" : "Informe uma Senha",
                "dateFormat" : "dd/mm/yy",
                "firstDayOfWeek" : "0",
                "emptyFilterMessage" : "Sem resultados', // @deprecated Use 'emptySearchMessage' option instea",
                "searchMessage" : "{0} resultados foram encontrados",
                "selectionMessage" : "{0} itens selecionados",
                "emptySelectionMessage" : "Item não selecionado",
                "emptySearchMessage" : "Nenhum resultado encontrado",
                "emptyMessage" : "Nenhuma opção disponível",
                "weekHeader" : "Sem",
                "aria.trueLabel" : "Verdadeiro",
                "aria.falseLabel" : "Falso",
                "aria.nullLabel" : "Não Selecionado",
                "aria.star" : "1 estrela",
                "aria.stars" : "{star} estrelas",
                "aria.selectAll" : "Selecionar todos os itens",
                "aria.unselectAll" : "Selecionar nenhum item",
                "aria.close" : "Fechar",
                "aria.previous" : "Anterior",
                "aria.next" : "Próximo",
                "aria.navigation" : "Navegação",
                "aria.scrollTop" : "Ir para o topo",
                "aria.moveTop" : "Mover para o Início",
                "aria.moveUp" : "Mover para Cima",
                "aria.moveDown" : "Mover para Baixo",
                "aria.moveBottom" : "Mover para o Fim",
                "aria.moveToTarget" : "Mover para o Destino",
                "aria.moveToSource" : "Mover para a Origem",
                "aria.moveAllToTarget" : "Mover Tudo para o Destino",
                "aria.moveAllToSource" : "Mover Tudo para a Origem",
                "aria.pageLabel" : "{page}",
                "aria.firstPageLabel" : "Primeira Página",
                "aria.lastPageLabel" : "Última Página",
                "aria.nextPageLabel" : "Próxima Página",
                "aria.prevPageLabel" : "Pagina Anterior",
                "aria.rowsPerPageLabel" : "Resultados por página",
                "aria.jumpToPageDropdownLabel" : "Ir para a Lista de Páginas",
                "aria.jumpToPageInputLabel" : "Ir para a Entrada da Página",
                "aria.selectRow" : "Linha Selecionada",
                "aria.unselectRow" : "Linha Não Selecionada",
                "aria.expandRow" : "Linha Expandida",
                "aria.collapseRow" : "Linha Recolhida",
                "aria.editRow" : "Editar Linha",
                "aria.saveEdit" : "Salvar Edição",
                "aria.cancelEdit" : "Cancelar Edição",
                "aria.listView" : "Visualização em Lista",
                "aria.gridView" : "Visualização em Tabela",
                "aria.slide" : "Slide",
                "aria.slideNumber" : "{slideNumber}",
                "aria.zoomImage" : "Ampliar Imagem",
                "aria.zoomIn" : "Ampliar o Zoom",
                "aria.zoomOut" : "Reduzir o Zoom",
                "aria.rotateRight" : "Girar para a Esquerda",
                "aria.rotateLeft" : "Girar para a Direita",
                "aria.showFilterMenu" : "Show Filter Menu",
                "aria.hideFilterMenu" : "Hide Filter Menu",
                "aria.filterOperator" : "Filter Operator",
                "aria.filterConstraint" : "Filter Constraint",
            }
        });

        app.directive('tooltip', Tooltip);
        app.directive('ripple', Ripple);

        app.component('Accordion', Accordion);
        app.component('AccordionTab', AccordionTab);
        app.component('AutoComplete', AutoComplete);
        app.component('BlockUI', BlockUI);
        app.component('Breadcrumb', Breadcrumb);
        app.component('Button', Button);
        app.component('Calendar', Calendar);
        app.component('Card', Card);
        app.component('Carousel', Carousel);
        app.component('Checkbox', Checkbox);
        app.component('Chips', Chips);
        app.component('ColorPicker', ColorPicker);
        app.component('Column', Column);
        app.component('ConfirmPopup', ConfirmPopup);
        app.component('ConfirmDialog', ConfirmDialog);
        app.component('ContextMenu', ContextMenu);
        app.component('DataTable', DataTable);
        app.component('DataView', DataView);
        app.component('DataViewLayoutOptions', DataViewLayoutOptions);
        app.component('Dialog', Dialog);
        app.component('Dropdown', Dropdown);
        app.component('Fieldset', Fieldset);
        app.component('FileUpload', FileUpload);
        app.component('InlineMessage', InlineMessage);
        app.component('Inplace', Inplace);
        app.component('InputMask', InputMask);
        app.component('InputNumber', InputNumber);
        app.component('InputSwitch', InputSwitch);
        app.component('InputText', InputText);
        app.component('Galleria', Galleria);
        app.component('Listbox', Listbox);
        app.component('MegaMenu', MegaMenu);
        app.component('Menu', Menu);
        app.component('Menubar', Menubar);
        app.component('Message', Message);
        app.component('MultiSelect', MultiSelect);
        app.component('OrderList', OrderList);
        app.component('OrganizationChart', OrganizationChart);
        app.component('OverlayPanel', OverlayPanel);
        app.component('Paginator', Paginator);
        app.component('Panel', Panel);
        app.component('PanelMenu', PanelMenu);
        app.component('Password', Password);
        app.component('PickList', PickList);
        app.component('ProgressBar', ProgressBar);
        app.component('ProgressSpinner', ProgressSpinner);
        app.component('RadioButton', RadioButton);
        app.component('Rating', Rating);
        app.component('SelectButton', SelectButton);
        app.component('Slider', Slider);
        app.component('Sidebar', Sidebar);
        app.component('SplitButton', SplitButton);
        app.component('Steps', Steps);
        app.component('TabMenu', TabMenu);
        app.component('TabView', TabView);
        app.component('TabPanel', TabPanel);
        app.component('Tag', Tag);
        app.component('Textarea', Textarea);
        app.component('TieredMenu', TieredMenu);
        app.component('Toast', Toast);
        app.component('Toolbar', Toolbar);
        app.component('ToggleButton', ToggleButton);
        app.component('Tree', Tree);
        app.component('TreeTable', TreeTable);
        app.component('TriStateCheckbox', TriStateCheckbox);

        app.mount('#app');

    }

});

// #endregion Aplicação (APP) - Inicialização //

// #region Validação de Autenticação (Router) //

router.beforeEach(function(to, from, next) {

	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  
	if (requiresAuth && !auth.existsUsuario()) {
        next('login');
    } else {
        window.scrollTo(0, 0);
        next();
    }

});

// #endregion Validação de Autenticação (Router) //
