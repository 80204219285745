<template>

    <Dialog header="Imobiliária" :closable="false" :modal="true" v-model:visible="showDialog">
        <div class="p-field">
            <label for="imobiliaria" class="p-d-block">Selecione a Imobiliária:</label>
            <Dropdown
                id="imobiliaria"
                v-model="imobiliaria"
                :options="imobiliarias"
                :showClear="false"
                optionLabel="nome"
                placeholder="Selecione"
                :filter="true"
                filterPlaceholder="Buscar Imobiliária"
                style="width: 300px"
                @change="alterarImobiliaria($event)"
            />
            <Button 
                severity="success" 
                label="Confirmar" 
                icon="pi pi-check"
                iconPos="right"
                @click="alterarImobiliaria({ value: imobiliaria })" 
                v-if="imobiliariaGlobal"
            />
        </div>
    </Dialog>

</template>
<script>
export default {
    props: {
        imobiliarias: {
            type: Array,
            default: () => [],
        },
        show: {
            type: Boolean,
            default: false
        }
    },
	emits: [
		'complete'
	],
	data() {
		return {
			imobiliaria: null,
            imobiliariaGlobal: false,
            showDialog: false
		}
	},
    mounted() {
        if(this.$auth.existsImobiliaria() && this.imobiliarias.length > 0) {
            const imobiliaria = this.imobiliarias.find((i) => i.id == this.$auth.imobiliaria.id);
            if(typeof imobiliaria == "object" && imobiliaria != null)
            this.imobiliaria = imobiliaria;
        }
        this.showDialog = this.show;
    },
    watch: {
        show: function(s) {
            if(s) {
                if(this.$auth.existsImobiliaria() && this.imobiliarias.length > 0) {
					const imobiliaria = this.imobiliarias.find((i) => i.id == this.$auth.imobiliaria.id);
                    if(typeof imobiliaria == "object" && imobiliaria != null) {
                        this.imobiliaria = imobiliaria;
                        this.imobiliariaGlobal = true;
                    }
				}
            } else {
                this.imobiliaria = null;
                this.imobiliariaGlobal = false;
            }
            this.showDialog = s;
        }
    },
    methods: {
		alterarImobiliaria(e) {
			if(e.value === null) {
                this.$toast.add({
                    severity: "error",
                    summary: "Atenção!",
                    detail: "É obrigatória a seleção da Imobiliária de origem das informações.",
                    life: 5000,
                });
                return;
			}
            this.showDialog = false;
            this.$emit("complete", e.value);
		},
    }
};
</script>